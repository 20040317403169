// src/index.js
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import theme from './theme'; // Import the theme


const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}> {/* Apply the theme */}
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        redirectUri={window.location.origin}
        cacheLocation="localstorage" 
      >
        <App />
      </Auth0Provider>
    </ThemeProvider>

  </React.StrictMode>,
  document.getElementById('root')
);
