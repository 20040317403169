import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Container, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../App.css';
import Logo from './images/logo.webp';
import { useEffect } from 'react';

localStorage.setItem('jwtToken', '2c02292a-a336-432e-a447-463ca0dfaa25');

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    document.title = "cTrader";
  }, []);

  const handleDrawerToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const menuItems = [
    { label: 'Overview', path: '/' },
    { label: 'Trades', path: '/trades' },
    { label: 'Logs', path: '/logs' },
    { label: 'Account Management', path: '/account-management' },
    { label: 'Settings', path: '/settings' },
  ];

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            cTrader
          </Typography>
          {isMobile ? (
            <>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="right"
                open={mobileMenuOpen}
                onClose={handleDrawerToggle}
              >
                <List>
                  {menuItems.map((item) => (
                    <ListItem button component={RouterLink} to={item.path} key={item.label} onClick={() => setMobileMenuOpen(false)}>
                      <ListItemText primary={item.label} />
                    </ListItem>
                  ))}
                </List>
              </Drawer>
            </>
          ) : (
            menuItems.map((item) => (
              <Button color="inherit" component={RouterLink} to={item.path} key={item.label}>
                {item.label}
              </Button>
            ))
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
