// src/App.js
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import theme from './theme'; // Import the theme
import Overview from './components/Overview';
import Trades from './components/Trades';
import Logs from './components/Logs';
import Settings from './components/Settings';
import AccountManagement from './components/AccountManagement';
import AuthenticationButton from './AuthenticationButton';
import PrivateRoute from './components/PrivateRoute';
import Header from './components/Header'; // Import the Header component

function App() {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
    <Router>
      {isAuthenticated && <Header />} {/* Conditionally render the Header */}
      <div>
        <AuthenticationButton />
        <Routes>
          <Route path="/" element={
            <PrivateRoute>
              <Overview />
            </PrivateRoute>
          } />
          <Route path="/logs" element={
            <PrivateRoute>
              <Logs />
            </PrivateRoute>
          } />
          <Route path="/trades" element={
            <PrivateRoute>
              <Trades />
            </PrivateRoute>
          } />
          <Route path="/account-management" element={
            <PrivateRoute>
              <AccountManagement />
            </PrivateRoute>
          } />
          <Route path="/settings" element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          } />
        </Routes>
      </div>
    </Router>
    </ThemeProvider>
  );
}

export default App;
