import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const AuthenticationButton = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  // Only show the login button if the user is not authenticated
  if (!isAuthenticated) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh', // Adjust or remove minHeight as needed for your layout
        }}
      >
        <Button variant="contained" onClick={loginWithRedirect}>Log In</Button>
      </Box>
    );
  }

  // Return null or an empty fragment if the user is authenticated, so nothing is rendered
  return null;
};

export default AuthenticationButton;
