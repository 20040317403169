// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark', // Switches the theme to dark mode
  },
  // You can customize the theme further as needed
});

export default theme;
